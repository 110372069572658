import axios from "axios";
import {CONFIG} from "./apiConfig";


export const client = axios.create({
    baseURL:CONFIG.baseUrl,
    headers:{
        Accept: 'application/json'
    }
});

export const secureClient = axios.create({
    baseURL:CONFIG.baseUrl,
    headers:{
        Accept: 'application/json',
        "x-api-key": "JpOGQopBVy8bgRYLCJMOU401MPJ8NNsS3yZZz2oN"
    }
});
