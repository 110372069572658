import React, {Component} from 'react'
import {LinearProgress, Box} from "@material-ui/core";

function Loading() {
    return (
        <div className="fullScreenLoader">
            <span className='loaderCenterCol'>
                <div className='fullWidth justifyCenter marginBottom10' style={{height: 15}}>
                    <div className="stage">
                    <div className="dot-pulse"></div>
                  </div>
                </div>
                <span>Loading...</span>
            </span>
        </div>
    )
}

export default Loading;
